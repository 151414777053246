<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_title_01.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Responsável pela proteção da edificação de chuva e sol, o
                  telhado é a parte superior composta por planos inclinados
                  chamados de “águas”. No caso de só haver um plano inclinado
                  por onde escorrem as águas de chuva, é considerado de meia
                  água e também conhecido como de uma água. Se houver queda para
                  dois lados opostos, o telhado é de duas águas. Nesse caso, o
                  ponto mais alto da cobertura recebe o nome de cumeeira. Se
                  além das duas águas houver uma terceira queda perpendicular a
                  essas duas, têm-se o telhado de três águas. Existem ainda os
                  telhados onde são feitas quatro águas, sendo duas com caimento
                  perpendicular e duas com queda paralela à cumeeira. O telhado
                  é composto por dois elementos construtivos fundamentais: a sua
                  estrutura e o material de cobertura.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_01.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de edificações acessórias de um terreno da comunidade de
            Pedras. O paiol, à esquerda da imagem, possui cobertura de quatro
            águas, a venda e depósito de lenha apresenta telhado de duas águas e
            a garagem possui cobertura disposta em uma água. Foto: Vistoria
            técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_02.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista superior de uma casa da comunidade de Campina com telhado
            disposto em quatro águas. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title_alignment="center"
              title="A estrutura e engradamento"
            >
              <template v-slot:conteudo>
                <p>
                  Nas edificações antigas e até mesmo nas mais recentes
                  executadas com técnicas tradicionais, a estrutura de
                  assentamento das telhas é sempre de madeira. O desdobramento
                  (corte) das peças é realizado de forma artesanal, manual, com
                  uso de ferramentas. O madeiramento das construções mais
                  antigas era feito somente com o encaixe entre as peças de
                  madeira, sem uso de parafusos ou pregos. Essa técnica é
                  chamada de ensamblagem. A técnica de ensamblar consiste em
                  embutir peças de madeira por meio de entalhe.
                </p>
                <p>
                  A estrutura principal de apoio ocorre geralmente através das
                  tesouras. Entretanto, nas construções mais antigas do período
                  colonial, os telhados eram feitos com estruturas bastante
                  simples chamadas de armação sobre empenas ou utilizando-se o
                  sistema de caibro armado e a cangalha.
                </p>
                <p>
                  A armação sobre empenas ocorre principalmente em telhados de
                  duas águas, onde os caibros vencem o vão entre duas paredes
                  paralelas e se apoiam em peças de madeira instaladas ao longo
                  do topo da alvenaria (frechal). No ponto de cumeeira, os
                  caibros se apoiam em uma peça de madeira de seção quadrada,
                  que se estende entre as empenas triangulares das fachadas.
                  Dependendo da seção dos caibros e do vão a ser vencido, são
                  inseridas peças intermediárias para apoiá-los, conhecidas como
                  terças.
                </p>
                <p>
                  O sistema de caibro armado (sem tesouras) é feito com uma
                  sequência de caibros que recebiam uma trava ou tirante no
                  terço superior. Essa trava é chamada de olivel ou linha alta.
                  Para o travamento adequado e funcionamento para absorção dos
                  esforços, as peças recebem os entalhes ou sambladuras. O
                  encaixe no topo dos caibros, junto ao ponto de cumeeira é do
                  tipo “meia madeira”, e a ligação entre a linha alta (trava) e
                  os caibros é chamada de “rabo de andorinha”; já o encaixe
                  entre os caibros e o frechal é do tipo “boca de lobo”.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_03.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Sustentação do telhado composto pela linha baixa, pelo pendural e
            pela cumeeira, sem as asnas. Os caibros se apoiam na viga de
            cumeeira e nos frechais (cintas). Foto: Vistoria técnica da Cáritas,
            2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_04.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Curral em terreno de Pedras, com telhado sustentado por tesouras
            compostas pela linha baixa, asnas, pendural e escoras, além das
            terças onde se apóiam os caibros. Foto: Vistoria técnica da Cáritas,
            2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A estrutura do tipo “cangalha” ou “canga de porco simples”
                  recebeu esse nome pela semelhança com a armação colocada no
                  pescoço de suínos para impedir sua fuga dos cercados. As peças
                  inclinadas recebem o nome de “pernas”, e o encontro entre elas
                  no topo é feito com o encaixe do tipo “meia madeira”. A peça
                  de cumeeira é apoiada sobre as extremidades das pernas que
                  ultrapassam o encaixe. A linha alta é posicionada a meia
                  altura das pernas, e sobre elas são apoiadas as terças.
                </p>
                <p>
                  Ao longo do tempo, as estruturas principais já aparecem em
                  composição mais reforçada para vencer vãos maiores,
                  tratando-se das tesouras. São vigas treliçadas que, a partir
                  de peças solidarizadas, compõem um triângulo rígido. As
                  tesouras mais usualmente utilizadas em construções
                  residenciais são as sem pendural, com pendural e com pendural
                  e escoras. Outras mais elaboradas eram mais utilizadas em
                  templos religiosos ou prédios públicos.
                </p>
                <p>
                  As tesouras sem pendural são as mais simples, onde as peças
                  compõem um triângulo rígido, que não se deforma. São
                  executadas com duas pernas e a linha baixa. As tesouras com
                  pendural possuem duas pernas, a linha baixa e o pendural,
                  sobre o qual é apoiada a peça de cumeerira. A tesoura com
                  pendural e escoras, além das duas pernas, da linha baixa e do
                  pendural, possui duas escoras que unem as pernas à extremidade
                  inferior do pendural, que sustenta a linha baixa. Esta última
                  composição, forma um quadro mais rígido e capaz de vencer
                  maiores vãos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_05.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Curral em Paracatu de Baixo com tesouras sustentando o telhado.
            Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_06.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Telhado de uma casa em Pedras a partir da cozinha, com sustentação
            simples onde os caibros se apoiam na viga de cumeeira e no frechal
            (cinta). Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title_alignment="center"
              title="Estrutura secundária de apoio às telhas"
            >
              <template v-slot:conteudo>
                <p>
                  Acima das estruturas principais, é instalada uma trama
                  composta por caibros e ripas sobre as terças. No caso dos
                  caibros armados e das cangalhas, as ripas são instaladas
                  diretamente sobre essas estruturas. O uso de terças e caibros
                  como estrutura secundária é de época mais recente. Os caibros
                  são peças dispostas no sentido da inclinação da cobertura (da
                  cumeeira ao beiral), apoiadas sobre as terças, funcionando
                  como sustentação das ripas e telhas. Já as ripas, são peças
                  colocadas longitudinalmente sobre os caibros, com a função de
                  apoiar as telhas. Nas construções mais antigas, o
                  encaibramento era executado de maneira variada, sendo comuns
                  as peças roliças, coletadas na mata. Era usual também serem
                  lavrados a machado, ou ainda, serrados.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_07.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista interna de um telhado de construção na comunidade de Pedras.
            Observam-se os caibros roliços e as ripas de madeira onde se apoiam
            as telhas. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_08.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de um telhado a partir da cozinha, onde se observam os caibros
            e ripas de sustentação das telhas. É típico que a fumaça dos fogões
            a lenha crie uma camada enegrecida na estrutura. Foto: Vistoria
            técnica da Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_09.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da varanda de um casarão em terreno rural de Paracatu de
            Baixo, onde se visualizam os caibros e as ripas que sustentam as
            telhas. Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_10.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da varanda de uma casa de Ponte do Gama, onde são observados
            os caibros e as ripas de sustentação das telhas. Foto: Vistoria
            técnica da Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title_alignment="center"
              title="Telhas"
            >
              <template v-slot:conteudo>
                <p>
                  As telhas utilizadas para cobrir as casas mais antigas são
                  sempre cerâmicas, do tipo capa e canal, ou capa e bica, também
                  conhecidas como colonial. Inicialmente, eram moldadas
                  artesanalmente por pessoas escravizadas e, naturalmente, o
                  resultado era bastante irregular devido à precariedade das
                  técnicas de produção. Isso gerou a crença popular de que eram
                  “feitas nas coxas” dos escravizados. A expressão inclusive
                  ultrapassou o discurso técnico, e é ainda hoje utilizada para
                  designar pejorativamente qualquer coisa mal feita.
                </p>
                <p>
                  O cozimento também não era perfeito, como viria a ser no
                  século XIX, quando aparecem as telhas francesas ou marselha e
                  as telhas romanas. O processo de moldagem e cozimento davam a
                  estas telhas forma e coloração muito características,
                  responsáveis pela aparência inconfundível das edificações
                  coloniais, incorporadas harmonicamente em sua estética.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_11.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Telhado de um galinheiro em Paracatu de Baixo coberto por telhas de
            barro curvas do tipo capa e bica. Foto: Vistoria técnica da Cáritas
            2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_12.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Edificação destinada à oficina em Ponte do Gama, com cobertura de
            telhas cerâmicas do tipo francesas. Foto: Vistoria técnica da
            Cáritas, 2020.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_13.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Telhado de uma casa em Ponte do Gama coberto por telhas cerâmicas do
            tipo plan. Foto: Vistoria técnica da Cáritas 2020.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img
            height="100%"
            src="../../assets/conteudos_casas_construcao_14.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Paiol coberto com telhas curvas de barro artesanais em Paracatu de
            Baixo. Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_pintura' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Pintura"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casa /",
          href: "conteudos_tematicos_casas",
        },

        {
          text: "Técnicas Construtivas Tradicionais /",
          href: "conteudos_tematicos_casas_tecnicas_construtivas",
        },

        {
          text: "O Telhado",
          href: "conteudos_tematicos_casas_telhado",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }
  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
